import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Title = makeShortcode("Title");
const GifPlayer = makeShortcode("GifPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<GifPlayer>`}</inlineCode>{` component is used to pause and play images that are gif’s. It
works by replacing the gif with a static image on pause.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row mdxType="Row">
      <Column colLg='4' mdxType="Column">
        <Title mdxType="Title">Light</Title>
        <GifPlayer mdxType="GifPlayer">
          <img {...{
            "src": "/eace5aec0b1ab4ae6425dc7088a68411/IBM_Cloud_Pictograms.gif",
            "alt": "IBM Cloud Pictograms"
          }}></img>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "480px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAeAlbc5xNAAf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAIBEBEg/9oACAEBAAEFAjKUZcjj/8QAFhEAAwAAAAAAAAAAAAAAAAAAESAh/9oACAEDAQE/ATU//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGRAAAQUAAAAAAAAAAAAAAAAAEQABECAh/9oACAEBAAY/Ap1EtX//xAAbEAACAgMBAAAAAAAAAAAAAAABEQAhECBBkf/aAAgBAQABPyEBw4K11L562df/2gAMAwEAAgADAAAAEGAAAP/EABYRAQEBAAAAAAAAAAAAAAAAABEBIP/aAAgBAwEBPxCkY//EABcRAQADAAAAAAAAAAAAAAAAAAERICH/2gAIAQIBAT8QDJp//8QAHRABAQACAQUAAAAAAAAAAAAAAREhQQAQIDFhcf/aAAgBAQABPxB3DWVdcgUafJ0d2AjL4w2cREqXdLoPXb//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IBM Cloud Pictograms",
              "title": "IBM Cloud Pictograms",
              "src": "/static/35f178dcd2991ea7403a3bf70ed0533d/ee01e/IBM_Cloud_Pictograms.jpg",
              "srcSet": ["/static/35f178dcd2991ea7403a3bf70ed0533d/69549/IBM_Cloud_Pictograms.jpg 288w", "/static/35f178dcd2991ea7403a3bf70ed0533d/ee01e/IBM_Cloud_Pictograms.jpg 480w"],
              "sizes": "(max-width: 480px) 100vw, 480px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </GifPlayer>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg='8' mdxType="Column">
        <Title mdxType="Title">Dark</Title>
        <GifPlayer color='dark' mdxType="GifPlayer">
          <img {...{
            "src": "/4a9aad9f2d6ef59256ca8dfbb9936c5b/IBM_Cloud_Platform_Prototype.gif",
            "alt": "IBM Cloud Platform Prototype"
          }}></img>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1120px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFjQWksAf/EABoQAQACAwEAAAAAAAAAAAAAAAEQEQISExT/2gAIAQEAAQUCV9XPGKNrY//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAEEAgMAAAAAAAAAAAAAAAEAAhBBERIhMZH/2gAIAQEABj8CYzPBCv2NrFrsx//EABwQAQACAQUAAAAAAAAAAAAAAAEAETEQQVHh8f/aAAgBAQABPyFFyNUGWd2jiNo4T3IYn//aAAwDAQACAAMAAAAQEA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAeEAEAAgEEAwAAAAAAAAAAAAABESEAEDFBUWHw8f/aAAgBAQABPxCvCggoPWG0fV5wIIuqvI+OLsB4M+00v//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IBM Cloud Platform Prototype",
              "title": "IBM Cloud Platform Prototype",
              "src": "/static/80dd337880305f90105eb98c2fb1c156/177a2/IBM_Cloud_Platform_Prototype.jpg",
              "srcSet": ["/static/80dd337880305f90105eb98c2fb1c156/69549/IBM_Cloud_Platform_Prototype.jpg 288w", "/static/80dd337880305f90105eb98c2fb1c156/473e3/IBM_Cloud_Platform_Prototype.jpg 576w", "/static/80dd337880305f90105eb98c2fb1c156/177a2/IBM_Cloud_Platform_Prototype.jpg 1120w"],
              "sizes": "(max-width: 1120px) 100vw, 1120px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </GifPlayer>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <p>{`Place two images inside of the GifPlayer component. The first image will be used
as the gif, the second image will be used as the static image on pause. Only
provide two images inside the component, do not place any other children inside
the component.`}</p>
    <Title mdxType="Title">Light</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/GifPlayer/GifPlayer.js src= https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/GifPlayer",
        "path": "components/GifPlayer/GifPlayer.js",
        "src": "",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/GifPlayer": true
      }}>{`<Column colLg='4'>
<GifPlayer>

![IBM Cloud Pictograms](/images/IBM_Cloud_Pictograms.gif) // must be gif

![IBM Cloud Pictograms](/images/IBM_Cloud_Pictograms.jpg) // must be static
image

</GifPlayer>
</Column>
`}</code></pre>
    <Title mdxType="Title">Dark</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/GifPlayer/GifPlayer.js src= https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/GifPlayer",
        "path": "components/GifPlayer/GifPlayer.js",
        "src": "",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/GifPlayer": true
      }}>{`<Column colLg='8'>
<GifPlayer color='dark'>

![IBM Cloud Platform Prototype](/images/IBM_Cloud_Platform_Prototype.gif) //
must be gif

![IBM Cloud Platform Prototype](/images/IBM_Cloud_Platform_Prototype.jpg) //must
be static image

</GifPlayer>
</Column>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pass in the images that will be rendered. Only pass in the images, no other children`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify if the icon color should be light or dark`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify an optional className to be applied to the container node`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      